<template>
    <path id="Vector (Stroke)_28" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 87H139.031L173.547 156.033L139.031 225.065H47.513L13 155.891L47.5194 87ZM54.2318 97.8713L25.1544 155.902L54.2383 214.194H132.312L161.393 156.033L132.312 97.8713H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_29" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 246H139.031L173.547 315.033L139.031 384.065H47.513L13 314.891L47.5194 246ZM54.2318 256.871L25.1544 314.902L54.2383 373.194H132.312L161.393 315.033L132.312 256.871H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_30" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 403H139.031L173.547 472.033L139.031 541.065H47.513L13 471.891L47.5194 403ZM54.2318 413.871L25.1544 471.902L54.2383 530.194H132.312L161.393 472.033L132.312 413.871H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_31" fill-rule="evenodd" clip-rule="evenodd"
        d="M182.519 328H274.031L308.547 397.033L274.031 466.065H182.513L148 396.891L182.519 328ZM189.232 338.871L160.154 396.902L189.238 455.194H267.312L296.393 397.033L267.312 338.871H189.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_32" fill-rule="evenodd" clip-rule="evenodd"
        d="M182.519 171H274.031L308.547 240.033L274.031 309.065H182.513L148 239.891L182.519 171ZM189.232 181.871L160.154 239.902L189.238 298.194H267.312L296.393 240.033L267.312 181.871H189.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_33" fill-rule="evenodd" clip-rule="evenodd"
        d="M182.519 12H274.031L308.547 81.0326L274.031 150.065H182.513L148 80.8915L182.519 12ZM189.232 22.8713L160.154 80.9018L189.238 139.194H267.312L296.393 81.0325L267.312 22.8713H189.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_34" fill-rule="evenodd" clip-rule="evenodd"
        d="M319.519 90H411.031L445.547 159.033L411.031 228.065H319.513L285 158.891L319.519 90ZM326.232 100.871L297.154 158.902L326.238 217.194H404.312L433.393 159.033L404.312 100.871H326.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_35" fill-rule="evenodd" clip-rule="evenodd"
        d="M319.519 244H411.031L445.547 313.033L411.031 382.065H319.513L285 312.891L319.519 244ZM326.232 254.871L297.154 312.902L326.238 371.194H404.312L433.393 313.033L404.312 254.871H326.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_36" fill-rule="evenodd" clip-rule="evenodd"
        d="M319.519 401H411.031L445.547 470.033L411.031 539.065H319.513L285 469.891L319.519 401ZM326.232 411.871L297.154 469.902L326.238 528.194H404.312L433.393 470.033L404.312 411.871H326.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_37" fill-rule="evenodd" clip-rule="evenodd"
        d="M452.519 327H544.031L578.547 396.033L544.031 465.065H452.513L418 395.891L452.519 327ZM459.232 337.871L430.154 395.902L459.238 454.194H537.312L566.393 396.033L537.312 337.871H459.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_38" fill-rule="evenodd" clip-rule="evenodd"
        d="M452.519 170H544.031L578.547 239.033L544.031 308.065H452.513L418 238.891L452.519 170ZM459.232 180.871L430.154 238.902L459.238 297.194H537.312L566.393 239.033L537.312 180.871H459.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_39" fill-rule="evenodd" clip-rule="evenodd"
        d="M452.519 13H544.031L578.547 82.0326L544.031 151.065H452.513L418 81.8915L452.519 13ZM459.232 23.8713L430.154 81.9018L459.238 140.194H537.312L566.393 82.0325L537.312 23.8713H459.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_40" fill-rule="evenodd" clip-rule="evenodd"
        d="M587.519 88H679.031L713.547 157.033L679.031 226.065H587.513L553 156.891L587.519 88ZM594.232 98.8713L565.154 156.902L594.238 215.194H672.312L701.393 157.033L672.312 98.8713H594.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_41" fill-rule="evenodd" clip-rule="evenodd"
        d="M587.519 244H679.031L713.547 313.033L679.031 382.065H587.513L553 312.891L587.519 244ZM594.232 254.871L565.154 312.902L594.238 371.194H672.312L701.393 313.033L672.312 254.871H594.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_42" fill-rule="evenodd" clip-rule="evenodd"
        d="M587.519 403H679.031L713.547 472.033L679.031 541.065H587.513L553 471.891L587.519 403ZM594.232 413.871L565.154 471.902L594.238 530.194H672.312L701.393 472.033L672.312 413.871H594.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_43" fill-rule="evenodd" clip-rule="evenodd"
        d="M726.519 328H818.031L852.547 397.033L818.031 466.065H726.513L692 396.891L726.519 328ZM733.232 338.871L704.154 396.902L733.238 455.194H811.312L840.393 397.033L811.312 338.871H733.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_44" fill-rule="evenodd" clip-rule="evenodd"
        d="M726.519 169H818.031L852.547 238.033L818.031 307.065H726.513L692 237.891L726.519 169ZM733.232 179.871L704.154 237.902L733.238 296.194H811.312L840.393 238.033L811.312 179.871H733.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_45" fill-rule="evenodd" clip-rule="evenodd"
        d="M726.519 11H818.031L852.547 80.0326L818.031 149.065H726.513L692 79.8915L726.519 11ZM733.232 21.8713L704.154 79.9018L733.238 138.194H811.312L840.393 80.0325L811.312 21.8713H733.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_46" fill-rule="evenodd" clip-rule="evenodd"
        d="M865.519 86H957.031L991.547 155.033L957.031 224.065H865.513L831 154.891L865.519 86ZM872.232 96.8713L843.154 154.902L872.238 213.194H950.312L979.393 155.033L950.312 96.8713H872.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_47" fill-rule="evenodd" clip-rule="evenodd"
        d="M865.519 245H957.031L991.547 314.033L957.031 383.065H865.513L831 313.891L865.519 245ZM872.232 255.871L843.154 313.902L872.238 372.194H950.312L979.393 314.033L950.312 255.871H872.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_48" fill-rule="evenodd" clip-rule="evenodd"
        d="M865.519 404H957.031L991.547 473.033L957.031 542.065H865.513L831 472.891L865.519 404ZM872.232 414.871L843.154 472.902L872.238 531.194H950.312L979.393 473.033L950.312 414.871H872.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_49" fill-rule="evenodd" clip-rule="evenodd"
        d="M1004.52 328H1096.03L1130.55 397.033L1096.03 466.065H1004.51L970 396.891L1004.52 328ZM1011.23 338.871L982.154 396.902L1011.24 455.194H1089.31L1118.39 397.033L1089.31 338.871H1011.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_50" fill-rule="evenodd" clip-rule="evenodd"
        d="M1004.52 170H1096.03L1130.55 239.033L1096.03 308.065H1004.51L970 238.891L1004.52 170ZM1011.23 180.871L982.154 238.902L1011.24 297.194H1089.31L1118.39 239.033L1089.31 180.871H1011.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_51" fill-rule="evenodd" clip-rule="evenodd"
        d="M1004.52 11H1096.03L1130.55 80.0326L1096.03 149.065H1004.51L970 79.8915L1004.52 11ZM1011.23 21.8713L982.154 79.9018L1011.24 138.194H1089.31L1118.39 80.0325L1089.31 21.8713H1011.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_52" fill-rule="evenodd" clip-rule="evenodd"
        d="M1140.52 88H1232.03L1266.55 157.033L1232.03 226.065H1140.51L1106 156.891L1140.52 88ZM1147.23 98.8713L1118.15 156.902L1147.24 215.194H1225.31L1254.39 157.033L1225.31 98.8713H1147.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_53" fill-rule="evenodd" clip-rule="evenodd"
        d="M1140.52 245H1232.03L1266.55 314.033L1232.03 383.065H1140.51L1106 313.891L1140.52 245ZM1147.23 255.871L1118.15 313.902L1147.24 372.194H1225.31L1254.39 314.033L1225.31 255.871H1147.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_54" fill-rule="evenodd" clip-rule="evenodd"
        d="M1140.52 402H1232.03L1266.55 471.033L1232.03 540.065H1140.51L1106 470.891L1140.52 402ZM1147.23 412.871L1118.15 470.902L1147.24 529.194H1225.31L1254.39 471.033L1225.31 412.871H1147.23Z"
        fill="#E8D9AA" />
</template>